export default {
  "section-container": "cUE",
  "disclaimer-section": "cUd",
  "trip-title": "cUI caption-bold mb-16",
  "google-link-container": "cUt",
  "link-icon": "cUM",
  "trip-details": "cUP body-2",
  "trip-details-icon": "cUl",
  "trip-details-title": "cUX",
  "subtitle": "cUC caption-bold mb-16",
  "disclaimer": "cUk",
  "visualizer": "cUJ"
};
