/* import __COLOCATED_TEMPLATE__ from './payment-details.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import { Badge, Disclaimer } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { SDD_INDIVIDUAL_COLLECTION_LIMIT } from 'qonto/constants/direct-debit-collections';
import { PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/invoice-subscriptions';
import ITALIAN_PAYMENT_CONDITIONS from 'qonto/constants/it-payment-conditions';
import ITALIAN_PAYMENT_METHODS from 'qonto/constants/it-payment-methods';
import {
  DEFAULT_PAYMENT_CONDITION,
  DEFAULT_PAYMENT_METHOD,
  SDD_PAYMENT_CONDITION,
  SDD_PAYMENT_METHOD,
  TERM_AND_CONDITIONS_MAX_LENGTH,
  TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT,
} from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class PaymentDetailsComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  badge = Badge;

  @service organizationManager;
  @service intl;

  @reads('organizationManager.organization.activeSortedAccounts') activeAccounts;
  @reads('args.document.customer.content') selectedCustomer;
  @reads('args.document.bankAccount.content') selectedAccount;
  @reads('args.isRecurringInvoice') isRecurringInvoice;
  @reads('args.sddEligibility') sddEligibility;

  constructor() {
    super(...arguments);
    if (!this.args.isQuote) {
      if (this.args.document.belongsTo('bankAccount').id() === null) {
        next(() => this._updateBankAccount(this.activeAccounts?.[0]));
      }
    }
  }

  get sddActivationQuery() {
    if (this.args.isEdit) {
      return {
        origin: 'invoice-subscriptions.edit',
      };
    }
    return {
      origin: 'invoice-subscriptions.new',
    };
  }

  get showSDDActivationCTA() {
    let { isEligible, isActivated, hasGuarding } = this.sddEligibility || {};

    return (
      this.isRecurringInvoice &&
      isEligible &&
      !isActivated &&
      (variation('feature--boolean-scale-sdd-rcur-invoice') || !hasGuarding)
    );
  }

  get guardingPeriodPercentage() {
    return this.intl.formatNumber(this.sddEligibility.guardingDepositPercentage, {
      style: 'percent',
    });
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get accountsToDebit() {
    return this.activeAccounts.filter(({ isRemunerated }) => !isRemunerated);
  }

  get hasBankAccountError() {
    return this.args.document.errors.has('bankAccount/data/id');
  }

  @cached
  get paymentMethods() {
    return Object.keys(ITALIAN_PAYMENT_METHODS).map(key => {
      return { key, label: key + ' - ' + ITALIAN_PAYMENT_METHODS[key] };
    });
  }

  get selectedPaymentMethod() {
    let paymentDocumentMethod = this.args.document.payment?.method;

    return this.paymentMethods.find(paymentMethod => paymentMethod.key === paymentDocumentMethod);
  }

  get showSelectorToggleSddDisclaimer() {
    return this.sddActive && !this.tooltipMessage;
  }

  get selectedPaymentMethodSdd() {
    return this.paymentMethods.find(paymentMethod => paymentMethod.key === SDD_PAYMENT_METHOD);
  }

  get selectedPaymentConditionsSdd() {
    return this.paymentConditions.find(
      paymentCondition => paymentCondition.key === SDD_PAYMENT_CONDITION
    );
  }

  @cached
  get paymentConditions() {
    return Object.keys(ITALIAN_PAYMENT_CONDITIONS).map(key => {
      return { key, label: key + ' - ' + ITALIAN_PAYMENT_CONDITIONS[key] };
    });
  }

  get selectedPaymentCondition() {
    let paymentDocumentCondition = this.args.document.payment?.conditions;

    return this.paymentConditions.find(
      paymentCondition => paymentCondition.key === paymentDocumentCondition
    );
  }

  get termsTitle() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.additional-notes.title');
    }
    return this.intl.t('receivable-invoices.invoice-creation.payment-details.terms.label');
  }

  get termsPlaceholder() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.additional-details.terms.placeholder');
    }
    return this.intl.t('receivable-invoices.invoice-creation.payment-details.terms.placeholder');
  }

  get getPdfLocale() {
    return this.selectedCustomer?.locale || this.intl.primaryLocale || 'en';
  }

  get getCapitalShareLabel() {
    return {
      en: 'with a capital of',
      fr: 'avec un capital de',
      de: 'mit einem Kapital von',
      it: 'con un capitale di',
      es: 'con un capital de',
    };
  }

  get formatCapitalShare() {
    return this.intl.formatNumber(this.args.settings?.legalCapitalShare, {
      locale: this.getPdfLocale,
      currency: 'EUR',
      style: 'currency',
    });
  }

  // This is the length used for legal info in the FR footer, it looks like:
  // 'Merchant Company, SAS with a capital of €1,000,000,000.00 · RCS A Paris 123 456 789 · '
  // As the invoice pdf footer has a strict limit, we need to check the amount of characters used, in order to calculate the remaining available amount of characters
  get getLengthUsedForFRFooter() {
    let { organization } = this.organizationManager;

    // Customer's locale
    let pdfLocale = this.getPdfLocale;
    // Capital share amount formatted in currency style
    let capitalShare = this.args.settings?.legalCapitalShare ? this.formatCapitalShare : '';
    // Label text for the capital share
    let capitalShareLabel = this.args.settings?.legalCapitalShare
      ? this.getCapitalShareLabel[pdfLocale]
      : '';
    // FR rsc number
    let rscNumber = this.args.settings?.commercialRegisterNumber
      ? this.args.settings.commercialRegisterNumber
      : '';
    // We add 3 characters because we separate the legal name from the rscNumber (if any) with " - "
    let dashSpace = this.args.settings?.commercialRegisterNumber ? 3 : 0;
    // We add space between the legal name and the legalCapitalShare
    let space = this.args.settings?.legalCapitalShare ? 2 : 0;

    return (
      `${organization.legalName}, ${organization.shortLegalForm}${capitalShareLabel}${capitalShare}${rscNumber}`
        .length +
      dashSpace +
      space
    );
  }

  get termsAndConditionsMaxLength() {
    let theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH;
    if (this.legalCountry === 'IT') {
      theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT;
    }

    let maxLength = theoreticalMaxLength - this.prefixTermsAndConditionsLength;
    return String(maxLength);
  }

  // As the invoice pdf footer has a strict limit, we need to check the amount of characters used, in order to calculate the remaining available amount of characters
  get prefixTermsAndConditionsLength() {
    let length = 0;

    let { organization } = this.organizationManager;

    // We add 3 characters because we separate the prefix from actual terms and conditions with " - "
    let dashSpace = 3;

    if (this.legalCountry === 'FR') {
      length = this.getLengthUsedForFRFooter + dashSpace;
    } else {
      length = `${organization.legalName}, ${organization.shortLegalForm}`.length + dashSpace;
    }

    return length;
  }

  get showEnableSDDToggle() {
    let {
      isActivated,
      hasPendingCreditorIdentifierVerification,
      hasPendingCreditorIdentifierCreation,
      hasGuarding,
    } = this.sddEligibility || {};

    return (
      this.isRecurringInvoice &&
      (isActivated ||
        hasPendingCreditorIdentifierVerification ||
        hasPendingCreditorIdentifierCreation) &&
      (variation('feature--boolean-scale-sdd-rcur-invoice') || !hasGuarding)
    );
  }

  get sddActive() {
    return this.args.document?.directDebitEnabled;
  }

  get tooltipMessage() {
    let { currency, totalAmount } = this.args.document;
    if (
      this.sddEligibility?.hasPendingCreditorIdentifierVerification ||
      this.sddEligibility?.hasPendingCreditorIdentifierCreation
    ) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.pending-tooltip'
      );
    } else if (currency !== 'EUR') {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.non-sepa-tooltip'
      );
    } else if (variation('feature--boolean-scale-sdd-rcur-invoice') && !this.selectedCustomer) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.tooltip.select-client'
      );
    } else if (this.selectedCustomer && !this.selectedCustomer?.email) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.missing-email-tooltip'
      );
    } else if (totalAmount > SDD_INDIVIDUAL_COLLECTION_LIMIT) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.sdd-toggle.amount-tooltip'
      );
    }
  }

  @action onClickToggle() {
    this.args.document.directDebitEnabled = !this.args.document?.directDebitEnabled;
    if (this.sddActive) {
      this.updatePaymentMethod({ key: SDD_PAYMENT_METHOD });
      this.updatePaymentCondition({ key: SDD_PAYMENT_CONDITION });
    } else {
      this.updatePaymentMethod({ key: DEFAULT_PAYMENT_METHOD });
      this.updatePaymentCondition({ key: DEFAULT_PAYMENT_CONDITION });
    }
  }

  @action onSddActivationFlowStart() {
    safeLocalStorage.setItem(PROMO_BOX_LOCAL_STORAGE_KEY, true);
  }

  @action onBankAccountSelect(account) {
    this._updateBankAccount(account);
  }

  @action updatePaymentMethod({ key }) {
    this.args.document.payment.method = key;
  }

  @action updatePaymentCondition({ key }) {
    this.args.document.payment.conditions = key;
  }

  _updateBankAccount(account) {
    this.args.document.bankAccount = account;
    this.args.document.iban = account?.iban;
    this.args.document.bic = account?.bic;
    this.args.document.beneficiaryName = this.organizationManager.organization.name;
  }
}
