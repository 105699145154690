export default {
  "referral-invite": "cKH",
  "referral": "cKO",
  "illustration": "cKm",
  "steps": "cKp",
  "step": "cKx",
  "share-referral": "cKw",
  "choice": "cKN",
  "line": "cKv",
  "email": "cKo",
  "email-input": "cKn",
  "input-field--error": "cKi",
  "error-message": "cKA",
  "external-link-icon": "cKY"
};
