export default {
  "wrapper": "qdQ",
  "content": "qde",
  "title": "qdB",
  "aside": "qdE",
  "description": "qdd",
  "faq-link": "qdI",
  "close-btn": "qdt",
  "container": "qdM",
  "mandate-field-list": "qdP",
  "mandate-field-item": "qdl",
  "mandate-field-item__dot": "qdX",
  "mandate-field-item__text": "qdC"
};
