export default {
  "sections": "qed",
  "actions": "qeI",
  "search-input": "qet",
  "no-upload": "qeM",
  "new-link": "qeP body-2",
  "between-links": "qel",
  "action-bar": "qeX",
  "empty-state": "qeC"
};
