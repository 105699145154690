export default {
  "header": "qqM",
  "header-type": "qqP",
  "close": "qql",
  "close-icon": "qqX",
  "header-infos": "qqC",
  "title": "qqk body-1",
  "declined": "qqJ",
  "canceled": "qqs",
  "approved": "qqF",
  "pending": "qqg",
  "date": "qqD body-2",
  "mileage-icon": "qqV",
  "distance-subtitle": "qqL body-2",
  "link-icon": "qqT",
  "container": "qqz",
  "picto": "qqa",
  "general": "qqH",
  "amount": "qqO",
  "calculation-container": "qqm",
  "counterparty": "qqp body-2",
  "infobox": "qqx"
};
