export default {
  "label-select": "qtT",
  "puce": "qtz",
  "puce-wrapper": "qta",
  "puce-container": "qtH",
  "readonly": "qtO",
  "label-display": "qtm",
  "label-list-name": "qtp body-1",
  "label-list-value": "qtx body-2"
};
