export default {
  "savings-account": "qBh",
  "closed": "qBf",
  "badge": "qBK",
  "subtitle": "qBG",
  "amount": "qBr",
  "processing": "qBb",
  "gain": "qBy",
  "active": "qBU",
  "progress": "qBj",
  "progress-bar": "qBW",
  "progress-text": "qBu",
  "info": "qES",
  "menu-item": "qEc body-2",
  "close-account": "qEq"
};
