export default {
  "container": "ciA",
  "text-content": "ciY",
  "text-wrapper": "cih",
  "actions-container": "cif",
  "illustration": "ciK",
  "feature-tour-link": "ciG",
  "link-illustration": "cir",
  "lottie": "cib"
};
