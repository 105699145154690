export default {
  "row": "qQt",
  "active": "qQM",
  "dot": "qQP",
  "red": "qQl",
  "green": "qQX",
  "yellow": "qQC",
  "status": "qQk",
  "align-right": "qQJ",
  "animated": "qQs",
  "fadein-item": "qQF",
  "cell": "qQg body-2",
  "cell-content": "qQD",
  "amount": "qQV body-2",
  "subtitle": "qQL caption",
  "padding-left": "qQT",
  "padding-right": "qQz",
  "note": "qQa"
};
