export default {
  "header-cell": "cbI",
  "empty": "cbt",
  "header-type": "cbM",
  "header-requester": "cbP",
  "header-date": "cbl",
  "header-amount": "cbX",
  "header-approval": "cbC",
  "header-content": "cbk caption-bold",
  "active": "cbJ",
  "header-receipt": "cbs",
  "row-compact": "cbF",
  "row-sidebar": "cbg",
  "hidden": "cbD",
  "animated": "cbV",
  "fadein-item": "cbL",
  "animated-cell": "cbT"
};
