export default {
  "row": "cur",
  "empty": "cub",
  "active": "cuy",
  "animated": "cuU",
  "fadein-item": "cuj",
  "cell": "cuW body-2",
  "cell-content": "cuu",
  "cell-amount": "qSS",
  "cell-content-amount": "qSc body-1"
};
