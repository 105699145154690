export default {
  "invoice-sidebar": "cAM",
  "body": "cAP",
  "box": "cAl",
  "box-header": "cAX",
  "footer": "cAC",
  "footer-button": "cAk",
  "footer-button-tooltip": "cAJ",
  "sidebar-box": "cAs",
  "border-top": "cAF",
  "row": "cAg",
  "status-container": "cAD",
  "due-date-container": "cAV",
  "greyed-out": "cAL",
  "struck-through": "cAT",
  "overdue": "cAz",
  "primary-actions": "cAa cAs",
  "primary-action": "cAH",
  "danger-action": "cAO",
  "button-icon": "cAm",
  "button-label": "cAp body-2",
  "related-credit-note-title": "cAx caption-bold",
  "paid-at-placeholder": "cAw"
};
