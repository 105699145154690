export default {
  "wrapper": "cNM",
  "per-page-container": "cNP",
  "options": "cNl",
  "option": "cNX",
  "active": "cNC",
  "description": "cNk",
  "description-per-page": "cNJ",
  "summary": "cNs"
};
