export default {
  "qonto-pilot-modal": "cNn",
  "content": "cNi",
  "header": "cNA",
  "transition-state-container": "cNY",
  "lottie": "cNh",
  "prompt-input": "cNf",
  "buttons": "cNK",
  "footer": "cNG caption",
  "divider": "cNr",
  "illustration": "cNb",
  "icon": "cNy",
  "quick-actions-container": "cNU",
  "action": "cNj body-2",
  "action-title": "cNW",
  "disabled": "cNu",
  "action-tag": "cvS caption-bold",
  "hotkey": "cvc body-1",
  "footer-action": "cvq",
  "grow": "cvZ",
  "footer-feedback-link": "cvR",
  "buttons-container": "cvQ",
  "transfer-details-container": "cve",
  "error-container": "cvB",
  "option-iban": "cvE",
  "actions": "cvd",
  "title": "cvI",
  "group-title": "cvt caption-bold",
  "no-result": "cvM",
  "lottie-illustration": "cvP",
  "beta-badge": "cvl",
  "hotkeys": "cvX",
  "supplier-invoices-dropzone": "cvC",
  "success-icon": "cvk",
  "error-icon": "cvJ",
  "file-items": "cvs",
  "file-item": "cvF",
  "uploader-file": "cvg"
};
