export default {
  "header": "qZq",
  "header-type": "qZZ",
  "close": "qZR",
  "close-icon": "qZQ",
  "header-infos": "qZe",
  "title": "qZB body-1",
  "declined": "qZE",
  "canceled": "qZd",
  "approved": "qZI",
  "date": "qZt body-2",
  "container": "qZM",
  "picto": "qZP",
  "request": "qZl",
  "picto-status": "qZX",
  "check": "qZC",
  "stop": "qZk",
  "warning": "qZJ",
  "general": "qZs",
  "amount": "qZF",
  "counterparty": "qZg body-2",
  "initiator": "qZD",
  "initiator-avatar": "qZV",
  "avatar-image": "qZL",
  "initiator-id": "qZT",
  "name": "qZz body-2",
  "role": "qZa caption-bold",
  "infobox": "qZH",
  "account-infobox": "qZO qZH",
  "beneficiary-warning": "qZm caption-bold",
  "dates-wrapper": "qZp",
  "dates": "qZx",
  "recurrence": "qZw caption-bold",
  "arrow": "qZN",
  "notify-checkbox": "qZv"
};
