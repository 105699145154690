export default {
  "cell-content": "cun",
  "icon": "cui",
  "status_check-m": "cuA",
  "description": "cuY body-1",
  "from": "cuh body-2",
  "from--abort": "cuf",
  "description--abort": "cuK",
  "title": "cuG body-2"
};
