export default {
  "section-container": "qqc",
  "disclaimer-section": "qqq",
  "trip-title": "qqZ caption-bold mb-16",
  "google-link-container": "qqR",
  "link-icon": "qqQ",
  "trip-details": "qqe body-2",
  "trip-details-icon": "qqB",
  "trip-details-title": "qqE",
  "subtitle": "qqd caption-bold mb-16",
  "disclaimer": "qqI",
  "visualizer": "qqt"
};
