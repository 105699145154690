export default {
  "insight-pill": "qli",
  "interactive": "qlA",
  "focus": "qlY",
  "subtitle": "qlh caption",
  "hidden": "qlf",
  "missing-amounts-warning": "qlK",
  "totals": "qlG",
  "counter": "qlr title-3",
  "loading": "qlb",
  "error": "qly",
  "title": "qlU title-4"
};
