export default {
  "header-cell": "qRl",
  "header-content": "qRX caption-bold",
  "active": "qRC",
  "empty": "qRk",
  "align-right": "qRJ",
  "request-type": "qRs",
  "requester": "qRF",
  "amount": "qRg"
};
