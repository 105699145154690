export default {
  "mileage-validation": "qqi",
  "actions": "qqA",
  "action-btn": "qqY",
  "approve-btn": "qqh",
  "request-mileage-validation": "qqf",
  "account-selector": "qqK",
  "has-error": "qqG",
  "funds-disclaimer": "qqr",
  "account-options": "qqb",
  "account-balance": "qqy",
  "warnings": "qqU"
};
