export default {
  "form": "qlP",
  "form-section": "qll",
  "form-title": "qlX title2",
  "divider": "qlC",
  "row": "qlk",
  "col": "qlJ",
  "actions": "qls",
  "buttons": "qlF"
};
