export default {
  "card-details-container": "qSt",
  "title": "qSM caption-bold mb-16",
  "section": "qSP",
  "divider": "qSl",
  "top-divider": "qSX",
  "content": "qSC body-2",
  "description": "qSk",
  "justify-end": "qSJ",
  "icon": "qSs ml-8"
};
