export default {
  "numbering-container": "cnn",
  "automatic-number-label": "cni",
  "icon-tooltip": "cnA",
  "fix-width": "cnY",
  "dates-container": "cnh",
  "with-performance-date": "cnf",
  "date-picker": "cnK",
  "label": "cnG",
  "header-text-field-container": "cnr",
  "mt-16": "cnb",
  "header-text-field": "cny",
  "header-text-field-close-button": "cnU"
};
