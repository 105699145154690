export default {
  "card-sidebar-header": "qSF",
  "content": "qSg",
  "header-type": "qSD",
  "close": "qSV",
  "close-icon": "qSL",
  "header-infos": "qST",
  "title": "qSz body-1",
  "danger": "qSa",
  "success": "qSH",
  "warning": "qSO",
  "date": "qSm body-2",
  "card-image-container": "qSp",
  "card-image": "qSx",
  "info-container": "qSw",
  "spending-reason": "qSN",
  "spending-reason-label": "qSv body-2 mr-8",
  "spending-reason-note": "qSo body-2"
};
