export default {
  "modal": "chZ",
  "action-container": "chR",
  "similar-label": "chQ",
  "info-icon": "che",
  "container": "chB",
  "subtitle": "chE caption-bold",
  "error": "chd",
  "item-container": "chI"
};
