export default {
  "team-card": "qsW",
  "card-link": "qsu",
  "shadow": "qFS",
  "history-button": "qFc",
  "cards-button": "qFq",
  "menu-item": "qFZ",
  "subtitle": "qFR",
  "delete-team": "qFQ"
};
