export default {
  "row": "qsc",
  "active": "qsq",
  "dot": "qsZ",
  "red": "qsR",
  "green": "qsQ",
  "yellow": "qse",
  "align-right": "qsB",
  "animated": "qsE",
  "fadein-item": "qsd",
  "cell": "qsI body-2",
  "cell-content": "qst",
  "amount": "qsM body-1",
  "subtitle": "qsP caption",
  "status": "qsl",
  "cell-amount": "qsX"
};
