export default {
  "invoice-sidebar": "cAG",
  "body": "cAr",
  "box": "cAb",
  "box-header": "cAy",
  "footer": "cAU",
  "footer-button": "cAj",
  "sidebar-box": "cAW",
  "border-top": "cAu",
  "row": "cYS",
  "status-container": "cYc",
  "due-date-container": "cYq",
  "mark-as-btn": "cYZ",
  "primary-actions": "cYR cAW",
  "primary-action": "cYQ body-1",
  "button-icon": "cYe"
};
