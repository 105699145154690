export default {
  "section-container": "crZ",
  "disclaimer-section": "crR",
  "trip-title": "crQ caption-bold mb-16",
  "google-link-container": "cre",
  "link-icon": "crB",
  "trip-details": "crE body-2",
  "trip-details-icon": "crd",
  "trip-details-title": "crI",
  "subtitle": "crt caption-bold mb-16",
  "disclaimer": "crM",
  "visualizer": "crP"
};
