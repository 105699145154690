export default {
  "wrapper": "qew",
  "header": "qeN mb-48",
  "form-title": "qev title-2 mb-16",
  "form-subtitle": "qeo body-2",
  "form": "qen",
  "section": "qei mb-48",
  "section-title": "qeA title-3 mb-16",
  "form-footer": "qeY",
  "footer-btn": "qeh",
  "transfer-form": "qef"
};
