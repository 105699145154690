export default {
  "wrapper": "cKy",
  "tabs": "cKU",
  "tabs-nav": "cKj",
  "tabs-panel-wrapper": "cKW",
  "list-head": "cKu",
  "list": "cGS",
  "footer": "cGc",
  "reward-amount": "cGq",
  "earned": "cGZ",
  "pending": "cGR",
  "empty-state-wrapper": "cGQ",
  "empty-state": "cGe",
  "illustration": "cGB",
  "error-wrapper": "cGE",
  "error-illustration": "cGd",
  "error-description": "cGI",
  "spinner": "cGt",
  "total-earned": "cGM"
};
