export default {
  "header": "crl",
  "header-type": "crX",
  "close": "crC",
  "close-icon": "crk",
  "header-infos": "crJ",
  "title": "crs body-1",
  "declined": "crF",
  "canceled": "crg",
  "approved": "crD",
  "pending": "crV",
  "date": "crL body-2",
  "mileage-icon": "crT",
  "distance-subtitle": "crz body-2",
  "link-icon": "cra",
  "container": "crH",
  "picto": "crO",
  "general": "crm",
  "amount": "crp",
  "calculation-container": "crx",
  "counterparty": "crw body-2",
  "infobox": "crN"
};
