export default {
  "header": "qMr",
  "subtitle": "qMb body-2",
  "date": "qMy",
  "subtitle-error": "qMU",
  "card": "qMj",
  "card-header": "qMW",
  "line-placeholder": "qMu",
  "post-closing-list": "qPS",
  "post-closing-container": "qPc",
  "post-closing-list-element": "qPq",
  "free-trial-disclaimer": "qPZ",
  "description": "qPR body-2",
  "mw-100": "qPQ",
  "addon-overview": "qPe",
  "addons-container": "qPB"
};
