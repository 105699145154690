export default {
  "attachment-viewer": "cyZ",
  "hide-sidebar": "cyR",
  "sidebar": "cyQ",
  "header": "cye",
  "header-top": "cyB",
  "header-icon": "cyE",
  "header-amount": "cyd",
  "infobox": "cyI",
  "vat": "cyt",
  "validation": "cyM"
};
