export default {
  "header": "qcE",
  "header-type": "qcd",
  "close": "qcI",
  "close-icon": "qct",
  "header-infos": "qcM",
  "title": "qcP body-1",
  "declined": "qcl",
  "canceled": "qcX",
  "approved": "qcC",
  "pending": "qck",
  "date": "qcJ body-2",
  "container": "qcs",
  "picto": "qcF",
  "picto-status": "qcg",
  "general": "qcD",
  "amount": "qcV",
  "counterparty": "qcL body-2",
  "initiator": "qcT",
  "initiator-avatar": "qcz",
  "avatar-image": "qca",
  "initiator-id": "qcH",
  "name": "qcO body-2",
  "role": "qcm caption-bold",
  "infobox": "qcp",
  "account-infobox": "qcx qcp",
  "beneficiary-warning": "qcw caption-bold",
  "dates-wrapper": "qcN",
  "dates": "qcv",
  "recurrence": "qco caption-bold",
  "arrow": "qcn",
  "notify-checkbox": "qci"
};
