export default {
  "row": "cGV",
  "active": "cGL",
  "animated": "cGT",
  "fadein-item": "cGz",
  "cell": "cGa",
  "cell-content": "cGH",
  "row-sidebar": "cGO",
  "empty": "cGm",
  "cell-amount": "cGp",
  "subtitle": "cGx caption",
  "hidden": "cGw",
  "cell-approve": "cGN",
  "quick-actions": "cGv",
  "account-select": "cGo",
  "note": "cGn",
  "cell-content-amount": "cGi body-1",
  "cell-status": "cGA"
};
