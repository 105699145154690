export default {
  "wrapper": "cuk",
  "request-header": "cuJ",
  "request": "cus",
  "scheduled": "cuF",
  "scheduled-label": "cug",
  "note": "cuD",
  "note-label": "cuV",
  "note-label-danger": "cuL",
  "note-content": "cuT",
  "status": "cuz",
  "transfer-amount": "cua"
};
