export default {
  "header": "cUs",
  "header-type": "cUF",
  "close": "cUg",
  "close-icon": "cUD",
  "header-infos": "cUV",
  "title": "cUL body-1",
  "declined": "cUT",
  "canceled": "cUz",
  "approved": "cUa",
  "pending": "cUH",
  "date": "cUO body-2",
  "mileage-icon": "cUm",
  "distance-subtitle": "cUp body-2",
  "link-icon": "cUx",
  "container": "cUw",
  "picto": "cUN",
  "general": "cUv",
  "amount": "cUo",
  "calculation-container": "cUn",
  "counterparty": "cUi body-2",
  "infobox": "cUA"
};
