export default {
  "card": "cxI",
  "card-primary": "cxt",
  "content": "cxM",
  "content-primary": "cxP",
  "disabled": "cxl",
  "illustration": "cxX",
  "illustration-primary": "cxC",
  "illustration-disabled": "cxk",
  "cta": "cxJ",
  "dismiss-button": "cxs",
  "buttons": "cxF",
  "required-badge": "cxg"
};
