export default {
  "iban": "qQr",
  "checkbox": "qQb",
  "toggle-content": "qQy",
  "beneficiary-name": "qQU",
  "category": "qQj",
  "beneficiary-email": "qQW",
  "beneficiary-form": "qQu",
  "beneficiary-email-container": "qeS"
};
