export default {
  "row": "cfz",
  "cell": "cfa",
  "greyed-out": "cfH",
  "struck-through": "cfO",
  "flex-cell": "cfm",
  "overdue": "cfp",
  "align-right": "cfx",
  "customer-name-container": "cfw",
  "icon": "cfN",
  "warning": "cfv",
  "with-separator": "cfo",
  "align-center": "cfn"
};
