export default {
  "section": "cYM",
  "header-section": "cYP",
  "header-title": "cYl",
  "header-item": "cYX",
  "item": "cYC",
  "center-align": "cYk",
  "content-gap": "cYJ",
  "cta-footer": "cYs",
  "section-content": "cYF"
};
