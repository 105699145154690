export default {
  "mileage-validation": "cUr",
  "actions": "cUb",
  "action-btn": "cUy",
  "action-btn-right": "cUU",
  "request-mileage-validation": "cUj",
  "account-selector": "cUW",
  "has-error": "cUu",
  "funds-disclaimer": "cjS",
  "account-options": "cjc",
  "account-balance": "cjq",
  "warnings": "cjZ"
};
