export default {
  "refunds-total": "qPP",
  "switch-recurrence": "qPl",
  "flex-wrapper": "qPX",
  "promo-code": "qPC",
  "promo-code-total": "qPk",
  "others": "qPJ",
  "others-total": "qPs",
  "subscription-detail": "qPF",
  "total-vat": "qPg",
  "fee-description": "qPD"
};
