export default {
  "request-transfer-validation": "qeb",
  "account-selector": "qey",
  "has-error": "qeU",
  "actions": "qej",
  "action-btn": "qeW",
  "warnings": "qeu",
  "top-border": "qBS",
  "account-selector-subtitle": "qBc",
  "action-btn-right": "qBq",
  "funds-disclaimer": "qBZ",
  "account-options": "qBR",
  "account-balance": "qBQ",
  "request-multi-transfer-validation": "qBe",
  "wrapper": "qBB",
  "warning-message-row": "qBE",
  "multi-transfer-align": "qBd",
  "button-approve": "qBI",
  "button-approve-spinner": "qBt",
  "button-decline": "qBM"
};
