export default {
  "item": "qXm",
  "details": "qXp",
  "flex": "qXx",
  "header": "qXw qXx",
  "flex-no-gap": "qXN qXx",
  "file-name": "qXv",
  "label": "qXo body-2",
  "self-icon": "qXn",
  "with-separator": "qXi",
  "dimmed": "qXA"
};
