export default {
  "supplier-selector-wrapper": "qlz",
  "select-supplier": "qla",
  "dropdown": "qlH",
  "iban": "qlO",
  "card": "qlm body-2",
  "add-btn": "qlp btn btn--square btn--icon-only",
  "archived-card": "qlx",
  "title": "qlw body-1",
  "archived-title": "qlN",
  "edit-supplier-tooltip": "qlv",
  "add-supplier": "qlo btn btn--tertiary",
  "error": "qln caption"
};
