export default {
  "wrapper": "cKt",
  "tabs": "cKM",
  "tabs-nav": "cKP",
  "tabs-panel": "cKl",
  "tabs-panel-wrapper": "cKX",
  "list-head": "cKC",
  "list": "cKk",
  "footer": "cKJ",
  "reward-amount": "cKs",
  "rewarded": "cKF",
  "registered": "cKg",
  "disclaimer": "cKD",
  "empty-state-wrapper": "cKV",
  "empty-state": "cKL",
  "illustration": "cKT",
  "spinner": "cKz",
  "total-earned": "cKa"
};
