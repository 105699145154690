export default {
  "row": "qsx",
  "icon": "qsw",
  "cell": "qsN",
  "cell-date": "qsv",
  "cell-amount": "qso",
  "cell-status": "qsn",
  "cell-content": "qsi",
  "align-right": "qsA"
};
