export default {
  "content": "cWf",
  "balance": "cWK",
  "duration": "cWG",
  "green-text": "cWr",
  "balance-subtitle": "cWb",
  "balance-duration": "cWy",
  "footer": "cWU",
  "progress": "cWj",
  "progress-bar": "cWW",
  "progress-bar-text": "cWu",
  "progress-active": "cuS"
};
