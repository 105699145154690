export default {
  "subtitle": "cWM",
  "green-text": "cWP",
  "balance-subtitle": "cWl",
  "options": "cWX",
  "shadow": "cWC",
  "menu-item": "cWk body-2",
  "close-account": "cWJ",
  "footer": "cWs",
  "progress": "cWF",
  "progress-bar": "cWg",
  "progress-bar-text": "cWD",
  "progress-active": "cWV"
};
