export default {
  "header-cell": "qJK",
  "header-content": "qJG caption-bold",
  "active": "qJr",
  "empty": "qJb",
  "type": "qJy",
  "requester": "qJU",
  "request-date": "qJj",
  "amount": "qJW",
  "receipt": "qJu",
  "quick-actions": "qsS"
};
