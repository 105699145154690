export default {
  "row": "qkI",
  "greyed-out": "qkt",
  "supplier-name": "qkM",
  "cell": "qkP",
  "supplier": "qkl",
  "email-address": "qkX",
  "outstanding-balance": "qkC",
  "quick-actions": "qkk",
  "empty": "qkJ",
  "supplier-container": "qks",
  "supplier-avatar": "qkF",
  "grey-background": "qkg"
};
