export default {
  "row": "cwA",
  "cell": "cwY",
  "title": "cwh",
  "internal-note": "cwf",
  "unit": "cwK",
  "price": "cwG",
  "quick-actions": "cwr",
  "empty": "cwb",
  "checkbox": "cwy"
};
