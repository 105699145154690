export default {
  "details": "qPO",
  "details-header": "qPm",
  "details-content": "qPp",
  "details-footer": "qPx",
  "invoice-status-container": "qPw body-2",
  "invoice-payment-date": "qPN",
  "overdue": "qPv",
  "details-list": "qPo",
  "details-list-item": "qPn",
  "details-list-item-label": "qPi body-2",
  "details-list-item-value": "qPA",
  "border-top": "qPY",
  "description": "qPh",
  "description-value": "qPf",
  "edit-button": "qPK body-2",
  "edit-icon": "qPG",
  "badge-union": "qPr",
  "edit-description": "qPb"
};
