export default {
  "modal": "qdp",
  "container": "qdx",
  "form-container": "qdw",
  "preview-container": "qdN",
  "preview-content": "qdv",
  "switch-container": "qdo",
  "supplier-invoice-preview": "qdn",
  "header": "qdi",
  "footer": "qdA",
  "form-content": "qdY",
  "form-content-section": "qdh",
  "form-disclaimer": "qdf",
  "hidden": "qdK"
};
