export default {
  "header-cell": "cGl",
  "header-content": "cGX caption-bold",
  "active": "cGC",
  "empty": "cGk",
  "request-type": "cGJ",
  "date": "cGs",
  "requester": "cGF",
  "amount": "cGg",
  "header-status": "cGD"
};
