export default {
  "cell": "qCB",
  "cell-label-container": "qCE",
  "cell-label": "qCd",
  "overflow": "qCI",
  "sub-label": "qCt body-2",
  "overdue": "qCM",
  "file-name-warning": "qCP",
  "missing": "qCl",
  "warning-icon": "qCX",
  "flex-label": "qCC",
  "with-separator": "qCk",
  "self-invoice-icon": "qCJ",
  "tooltip-wrapper": "qCs"
};
