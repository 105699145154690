export default {
  "row": "cjg",
  "active": "cjD",
  "dot": "cjV",
  "red": "cjL",
  "green": "cjT",
  "yellow": "cjz",
  "status": "cja",
  "align-right": "cjH",
  "animated": "cjO",
  "fadein-item": "cjm",
  "cell": "cjp body-2",
  "cell-content": "cjx",
  "amount": "cjw body-2",
  "subtitle": "cjN caption",
  "padding-left": "cjv",
  "padding-right": "cjo",
  "note": "cjn"
};
