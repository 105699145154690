export default {
  "sidepanel": "qCp",
  "close-icon": "qCx",
  "close-button": "qCw",
  "supplier-card": "qCN",
  "supplier-card-details": "qCv",
  "supplier-name": "qCo title-3 mb-8",
  "supplier-card-placeholder": "qCn",
  "box": "qCi",
  "box-element": "qCA",
  "related-invoices-title": "qCY",
  "placeholder-container": "qCh",
  "related-invoices-wrapper": "qCf",
  "related-invoice-placeholder": "qCK",
  "related-invoice-placeholder-inner": "qCG",
  "actions": "qCr",
  "currency": "qCb"
};
