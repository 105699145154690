export default {
  "container": "cYg",
  "form-container": "cYD",
  "content": "cYV",
  "fields": "cYL",
  "input-settings": "cYT",
  "current-year": "cYz",
  "next-invoice-number": "cYa",
  "preview-container": "cYH",
  "actions": "cYO",
  "close-button": "cYm btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cYp",
  "preview-image": "cYx"
};
