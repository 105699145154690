export default {
  "row": "cbz body-1",
  "active": "cba",
  "cell": "cbH",
  "empty": "cbO",
  "ellipsis": "cbm",
  "text-secondary": "cbp",
  "transfer-type": "cbx",
  "transfer-type__icon": "cbw",
  "quick-actions": "cbN",
  "amount": "cbv",
  "cell-approve": "cbo",
  "account-select": "cbn",
  "row-compact": "cbi",
  "cell-amount": "cbA",
  "cell-content-receipt": "cbY body-1",
  "cell-date": "cbh",
  "row-sidebar": "cbf",
  "hidden": "cbK",
  "animated": "cbG",
  "fadein-item": "cbr",
  "animated-cell": "cbb",
  "note": "cby"
};
