export default {
  "bank-account-option": "qsC",
  "row": "qsk",
  "active": "qsJ",
  "animated": "qss",
  "fadein-item": "qsF",
  "cell": "qsg body-2",
  "cell-content": "qsD",
  "subtitle": "qsV caption",
  "no-padding": "qsL",
  "empty": "qsT",
  "note": "qsz",
  "cell-content-amount": "qsa body-1",
  "cell-content-receipt": "qsH body-1",
  "cell-quick-actions": "qsO",
  "account-selector": "qsm",
  "cell-type": "qsp"
};
