export default {
  "progress-container": "qFC",
  "dashed-line": "qFk",
  "solid-line": "qFJ",
  "circle-container": "qFs",
  "circle": "qFF",
  "current-step-dot": "qFg",
  "last-step-dot": "qFD",
  "success-icon": "qFV",
  "rejected-icon": "qFL"
};
