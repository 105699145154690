export default {
  "item-wrapper": "qMv",
  "title-sub-wrapper": "qMo",
  "icon-title-wrapper": "qMn",
  "icon": "qMi",
  "icon-wrapper": "qMA",
  "title": "qMY body-1",
  "subtitle": "qMh body-2",
  "amount": "qMf body-1"
};
