export default {
  "referral-invite-new": "cfr",
  "referral": "cfb",
  "center": "cfy",
  "illustration": "cfU",
  "share-referral": "cfj",
  "choice": "cfW",
  "line": "cfu",
  "email": "cKS",
  "email-input": "cKc",
  "input-field--error": "cKq",
  "error-message": "cKZ",
  "external-link-icon": "cKR"
};
