export default {
  "sidebar": "cAN",
  "body": "cAv",
  "form": "cAo",
  "form-section": "cAn",
  "form-title": "cAi title2",
  "row": "cAA",
  "payment-details": "cAY",
  "col": "cAh",
  "footer": "cAf",
  "footer-button": "cAK"
};
