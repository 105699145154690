export default {
  "container": "cxH",
  "cards-container": "cxO",
  "card": "cxm",
  "expanded": "cxp",
  "hidden": "cxx",
  "collapsed": "cxw",
  "with-expand-collapse-animation": "cxN",
  "with-dismiss-animation": "cxv",
  "has-one-card": "cxo",
  "dismissing": "cxn",
  "with-hover-animation": "cxi",
  "toggle-button": "cxA",
  "toggle-container": "cxY",
  "toggle-button-icon": "cxh",
  "has-two-cards": "cxf"
};
