export default {
  "fullscreen-modal": "cxK",
  "creation-form-modal": "cxG",
  "links-container": "cxr",
  "link-field": "cxb",
  "link": "cxy",
  "add-link": "cxU",
  "remove-link": "cxj",
  "flex-row": "cxW",
  "flex-item": "cxu",
  "unit-trigger": "cwS",
  "dynamic-placeholders": "cwc"
};
