export default {
  "wrapper": "cWL",
  "card-header": "cWT",
  "subtitle-caption": "cWz",
  "estimated-earnings": "cWa",
  "progress": "cWH",
  "progress-bar": "cWO",
  "progress-bar-text": "cWm",
  "subtitle": "cWp",
  "badge": "cWx",
  "core-information": "cWw",
  "secondary-information": "cWN",
  "remuneration-table": "cWv",
  "name": "cWo title-3",
  "separator": "cWn",
  "remuneration-line": "cWi",
  "german-disclaimer": "cWA",
  "table": "cWY",
  "table-right": "cWh"
};
