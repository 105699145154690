export default {
  "header-cell": "qRO",
  "header-content": "qRm caption-bold",
  "active": "qRp",
  "empty": "qRx",
  "request-type": "qRw",
  "status": "qRN",
  "note": "qRv",
  "amount": "qRo"
};
