export default {
  "form-content": "chf",
  "row": "chK",
  "numbering-format": "chG",
  "numbering-fields": "chr",
  "input-settings": "chb",
  "next-invoice-number": "chy",
  "spacing": "chU",
  "label-tooltip": "chj",
  "tooltip-icon": "chW",
  "customize-field": "chu"
};
