export default {
  "header-cell": "cwH",
  "checkbox-cell": "cwO",
  "title-col": "cwm",
  "internal-note-col": "cwp",
  "unit-col": "cwx",
  "price-col": "cww",
  "header-content": "cwN caption-bold",
  "active": "cwv",
  "empty": "cwo"
};
