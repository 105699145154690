export default {
  "header": "cys",
  "header-type": "cyF",
  "close": "cyg",
  "close-icon": "cyD",
  "header-infos": "cyV",
  "title": "cyL body-1",
  "declined": "cyT",
  "canceled": "cyz",
  "approved": "cya",
  "pending": "cyH",
  "date": "cyO body-2",
  "container": "cym",
  "picto": "cyp",
  "picto-status": "cyx",
  "general": "cyw",
  "amount": "cyN",
  "counterparty": "cyv body-2",
  "initiator": "cyo",
  "initiator-avatar": "cyn",
  "avatar-image": "cyi",
  "initiator-id": "cyA",
  "name": "cyY body-2",
  "role": "cyh caption-bold",
  "infobox": "cyf",
  "account-infobox": "cyK cyf",
  "beneficiary-warning": "cyG caption-bold",
  "dates-wrapper": "cyr",
  "dates": "cyb",
  "recurrence": "cyy caption-bold",
  "arrow": "cyU",
  "notify-checkbox": "cyj"
};
