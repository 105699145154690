export default {
  "wrapper": "qek",
  "beneficiary": "qeJ",
  "name": "qes body-1",
  "beneficiary-email": "qeF body-2",
  "dropdown-icon": "qeg",
  "trusted": "qeD",
  "account-number": "qeV flex body-2",
  "account-icon": "qeL",
  "m-chip": "qeT",
  "dropdown": "qez body-2",
  "delete-option": "qea",
  "beneficiary-name": "qeH"
};
