export default {
  "attachment-viewer": "qSG",
  "hide-sidebar": "qSr",
  "sidebar": "qSb",
  "header": "qSy",
  "header-top": "qSU",
  "header-icon": "qSj",
  "header-amount": "qSW",
  "infobox": "qSu",
  "vat": "qcS",
  "validation": "qcc"
};
