export default {
  "header-cell": "qkS",
  "first-col": "qkc",
  "mid-col": "qkq",
  "outstanding-col": "qkZ",
  "header-content": "qkR caption-bold",
  "active": "qkQ",
  "quick-actions": "qke",
  "empty": "qkB"
};
