export default {
  "invoice-sidebar": "qIJ",
  "body": "qIs",
  "box": "qIF",
  "box-header": "qIg",
  "sidebar-box": "qID",
  "border-top": "qIV",
  "row": "qIL",
  "greyed-out": "qIT",
  "strike-through": "qIz"
};
