export default {
  "modal-wrapper": "chg",
  "spinner-container": "chD",
  "spinner": "chV",
  "container": "chL",
  "tabs-item": "chT",
  "form-container": "chz",
  "fieldset": "cha",
  "preview-container": "chH",
  "switch-container": "chO",
  "pdf-preview-container": "chm",
  "form-content": "chp",
  "row": "chx",
  "numbering-format": "chw",
  "numbering-fields": "chN",
  "input-settings": "chv",
  "next-invoice-number": "cho",
  "spacing": "chn",
  "label-tooltip": "chi",
  "tooltip-icon": "chA",
  "customize-field": "chY",
  "button-container-footer": "chh"
};
