export default {
  "mileage-validation": "crY",
  "actions": "crh",
  "action-btn": "crf",
  "action-btn-success": "crK",
  "action-btn-right": "crG",
  "request-mileage-validation": "crr",
  "account-selector": "crb",
  "has-error": "cry",
  "funds-disclaimer": "crU",
  "account-options": "crj",
  "account-balance": "crW",
  "warnings": "cru"
};
