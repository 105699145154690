export default {
  "row": "qRn",
  "active": "qRi",
  "dot": "qRA",
  "red": "qRY",
  "green": "qRh",
  "yellow": "qRf",
  "status": "qRK",
  "align-right": "qRG",
  "animated": "qRr",
  "fadein-item": "qRb",
  "cell": "qRy body-2",
  "cell-content": "qRU",
  "amount": "qRj body-1",
  "subtitle": "qRW caption",
  "no-padding": "qRu"
};
