export default {
  "insight-pill": "qlj",
  "insight-pill-figure": "qlW",
  "insight-pill-renderer": "qlu",
  "loading": "qXS",
  "counter": "qXc",
  "error": "qXq",
  "insight-pill-label": "qXZ",
  "insight-pill-subtitle": "qXR caption",
  "hidden": "qXQ",
  "missing-amounts-warning": "qXe",
  "missing-amounts": "qXB"
};
