export default {
  "payment-details": "ciV",
  "label-payment-conditions": "ciL",
  "dropdown-content": "ciT",
  "dropdown-option": "ciz",
  "activate-sdd-container": "cia",
  "activate-sdd-header": "ciH",
  "selector-toggle-sdd": "ciO",
  "selector-toggle-content": "cim",
  "selector-toggle": "cip",
  "new-badge": "cix",
  "new-badge-activation": "ciw",
  "mt-16": "ciN"
};
