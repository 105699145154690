export default {
  "row": "cGY",
  "icon": "cGh",
  "cell": "cGf",
  "cell-amount": "cGK",
  "cell-status": "cGG",
  "cell-date": "cGr",
  "cell-content": "cGb",
  "align-right": "cGy"
};
