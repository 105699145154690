export default {
  "item": "cht",
  "details": "chM",
  "flex": "chP",
  "header": "chl chP",
  "flex-no-gap": "chX chP",
  "file-name": "chC",
  "label": "chk body-2",
  "self-icon": "chJ",
  "with-separator": "chs",
  "dimmed": "chF"
};
