export default {
  "document": "cYb",
  "document-details": "cYy",
  "document-details-amount": "cYU body-2",
  "negative": "cYj",
  "positive": "cYW",
  "rejected": "cYu",
  "document-icon": "chS",
  "document-details-link": "chc body-link",
  "canceled": "chq"
};
