export default {
  "row": "qQS",
  "active": "qQc",
  "animated": "qQq",
  "fadein-item": "qQZ",
  "cell": "qQR body-2",
  "cell-content": "qQQ",
  "subtitle": "qQe caption",
  "no-padding": "qQB",
  "note": "qQE",
  "cell-amount": "qQd",
  "cell-content-amount": "qQI body-1"
};
