export default {
  "header-cell": "qJw",
  "header-content": "qJN caption-bold",
  "active": "qJv",
  "empty": "qJo",
  "align-right": "qJn",
  "type": "qJi",
  "requester": "qJA",
  "request-date": "qJY",
  "amount": "qJh",
  "status": "qJf"
};
