export default {
  "modal": "qXD",
  "action-container": "qXV",
  "similar-label": "qXL",
  "info-icon": "qXT",
  "container": "qXz",
  "subtitle": "qXa caption-bold",
  "error": "qXH",
  "item-container": "qXO"
};
